import React, { useContext } from "react";

export type GraphQLContextType = {
    url: string;
    requestHeaders: {
        Authorization: string;
    };
};

export const GraphQLContext = React.createContext<GraphQLContextType | null>(null);

export const useGraphQLContext = (): GraphQLContextType => {
    const graphQLContext = useContext(GraphQLContext);
    if (graphQLContext === null) {
        throw new Error("No GraphQL Context set!");
    }

    return graphQLContext;
};
